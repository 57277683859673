import React, {  useState, useContext } from "react";
import FrameContext from "./contexts/FrameContext.js";
import News from "./News";
import Admin from "./Admin";
import Portfolio from "./Portfolio";
import League from "./League";
import RestrictedNFLTable from "./RestrictedNFLTable.js";
import Market from "./Market.js";
import AdminStock from "./AdminStock.js";
import AdminGame from "./AdminGame.js";
import RestrictedTable from "./RestrictedTable.js";
import BodyHome from "./BodyHome.js";
import SiteNews from './SiteNews.js';

import './App.css';

const Body = (props) => {

  const {setFrame} = useContext(FrameContext);

  const [portInfo,setPortInfo] = useState({});
  const [leagueInfo,setLeagueInfo] = useState({});

  const frameSelect = props.table;


  //console.log("In the body, the table is " + props.table);


  const updatePortandGo = (record) => {
    console.log("update port and go")
    //console.log(e);
    console.log(record)
    console.log(record.port_type);
    setPortInfo(
      {
        port_name:record.port_name,
        port_id:record.port_id,
        port_type:record.port_type,
        port_format: record.port_format,
        port_timeframe: record.timeframe,
        subtimeframe: record.subtimeframe,
        port_year:record.port_year,
        port_sport:record.port_sport,
        port_sport_league:record.port_sport_league,
        port_sport_league_id:record.port_sport_league_id
      }
    );
    setFrame("Portfolios");
  }

  const updateLeagueandGo = (record) => {
    console.log("update league and go")
    //console.log(e);
    console.log(record)
    setLeagueInfo(
      {
        league_name:record.league_name,
        league_id:record.lg_id,
        league_type: record.lg_type,
        league_format: record.lg_format,
        league_timeframe: record.timeframe,
        league_start: record.week_start,
        league_end: record.week_end,
        league_sport_league: record.sport_league
      });
    setFrame("Leagues");
  }

  const componentMap = {
    "": null,
    Home: <BodyHome />,
    CreatePortfolio: <Portfolio type={"CreatePortfolio"} portCallback={updatePortandGo}/>,
    AddPortToLeague: <Portfolio type={"AddPortToLeague"} portCallback={updatePortandGo}/>,
    MyPortfolios: <Portfolio type={"MyPortfolios"} portCallback={updatePortandGo}/>,
    Portfolio: <Portfolio type={"Portfolio"} portCallback={updatePortandGo}/>,
    Portfolios: <Portfolio type={"Portfolios"} portInfo={portInfo} leagueCallback={updateLeagueandGo}/>,
    League: <League type={"League"} portCallback={updatePortandGo} leagueCallback={updateLeagueandGo}/>,
    CreateLeague: <League type={"CreateLeague"} portCallback={updatePortandGo} leagueCallback={updateLeagueandGo}/>,
    JoinLeague: <League type={"JoinLeague"} portCallback={updatePortandGo} leagueCallback={updateLeagueandGo}/>,
    MyLeagues: <League type={"MyLeagues"} portCallback={updatePortandGo} leagueCallback={updateLeagueandGo}/>,
    Leagues: <League type={"Leagues"} leagueInfo={leagueInfo} portCallback={updatePortandGo} leagueCallback={updateLeagueandGo}/>,
    Market: <Market year={2024} hist={false} type="Reg" sport="NFL" transact={false}/>,
    GlobalMarket: <div> Market<Market year={2024} transact={false}/> </div>,
    NCAAMMarket: <Market year={2024} hist={false} type="Playoffs" sport="NCAAM" transact={false}/>,
    NFLMarket: <Market year={2024} hist={false} type="Reg" sport="NFL" transact={false}/>,
    NFLPlayoffMarket: <Market year={2024} hist={false} type="Playoffs" sport="NFL" transact={false}/>,
    NBAmarket: <Market year={2024} hist={false} sport="NBA" transact={false}/>,
    Restricted: <RestrictedTable type="Reg" sport="NFL" />,
    RestrictNFLMarket: <RestrictedNFLTable type="Reg"/>,
    RestrictNFLPlayoffMarket: <RestrictedNFLTable type="Playoffs"/>,

    NFLMarket2023: <Market year={2023} hist={true} type="Reg" sport="NFL" transact={false}/>,
    NFLPlayoffMarket2023: <Market year={2023} hist={true} type="Playoffs" sport="NFL" transact={false}/>,

    News: <News />,
    Admin: <Admin />,
    AdminUniversal: <Admin />,
    AdminStock: <AdminStock />,
    AdminGame: <AdminGame />
  };





  return (
    <div>
      <div class="column side">
          <SiteNews />
      </div>
      <div class="column middle">
        {componentMap[frameSelect]}
      </div>
      <div class="column side"></div>
    </div>


  );
}

export default Body;
