

import React, { useState } from "react";

import axios from './axios.js';

import useAxiosPrivate from "./hooks/useAxiosPrivate.js";

import { Button, Dropdown, Menu, InputNumber,Form, Select, DatePicker, Radio } from "antd";

import {DownOutlined} from '@ant-design/icons';

import {NFLteamMap, NFLteamItems, NFLteamOptions} from './CONSTANTS.js';


const { Option} = Select;

const  AdminGame = (props) => {


  const labelSorter = ((a,b)=>{
    if (a.label>b.label){
      return 1;
    }
    else {
      return -1;
    }
  })

  
  NFLteamItems.sort(labelSorter);

  const [view,setView] = useState("All");
  const [prevView,setPrevView] = useState("");

  const axiosPrivate = useAxiosPrivate();
  //const [league,setLeague] = useState("Select A League");
  const [league,setLeague] = useState("NFL");
  const [team, setTeam] = useState(-1);
  const [teamName, setTeamName] = useState("Select A Team");
  const [teamItems, setTeamItems] = useState(NFLteamItems);
  const [teamMap, setteamMap] = useState(NFLteamMap);
  const [playerItems, setplayerItems] = useState([]);
  const [playerMap, setplayerMap] = useState(new Map());
  const [playerName,setPlayerName] = useState("Select A Player");
  const [player,setPlayer] = useState(-1);
  const [isSuccess,setIsSuccess] = useState(false);
  const [season,setSeason] = useState("Playoffs");
  const [timeframe,setTimeframe] = useState(4);
  const [week,setWeek] = useState(0);
  const [games,setGames] = useState({});
  const [gameOptions,setGameOptions] = useState([]);
  const [game,setGame] = useState(0);
  const [winner,setWinner] = useState(-1);
  const [home,setHome] = useState(-1);
  const [away,setAway] = useState(-1);


  const successfulUpdate = () => {
    setIsSuccess(true);
    setPrevView(view);
    setView("All");
  }

  const repeatAction = () => {
    setView(prevView);
    setIsSuccess(false);
  }

  const leagueUpdate = (lg) => {
    //add more logic when other season besides NFL added
    if (lg!==league){

      setTeam(-1);
      setTeamName("Select A Team");
      setPlayerName("Select A Player");
      fillTeamMenu(lg);
    }
    if (lg===1){
      setLeague("NFL");
      determineTimeframe(lg,season);

    }

  }

  const seasonUpdate = (szn) => {
    console.log("setting season to: " + szn)
    if (szn!==season) {
      setTeam(-1);
      setTeamName("Select A Team");
      setPlayerName("Select A Player");
    }
    setSeason(szn);

    determineTimeframe(league,szn);
  }

  const determineTimeframe = (lg,szn) => {
        //add more logic when other season besides NFL added
        console.log("season is  " + szn)
    if (szn===1){
      setTimeframe(3);
    }
    else if (szn === 2){
      setTimeframe(4);
    }
  }

  const winnerOnChange = (e) => {
    setWinner(e.target.value);
  }
 

  const submitPlayerStats = async (e) => {
    console.log ("Submitting player stats");
    console.log(e);


    //to get team   -- Document.getelementbyid - so need to assign id to <Input> boxes
    //console.log(e.team);
    //console.log("ret " + ret);
    //console.log("Access");
    //console.log(accessToken);

    if (league==='NFL'){
      //send NFL post to add team to db for nflteams
      var data = {
        game_id: game,
        player: player,
        pa_yards: e.pa_yards, pa_tds: e.pa_tds,
        ints: e.ints,
        ru_yards: e.ru_yards, ru_tds:e.ru_tds,
        fumb:e.fumb,
        rec:e.rec,re_yards:e.re_yards,re_tds:e.re_tds,
        twoptconv: e.twoptconv,
        opi:e.opi,dpi:e.dpi,
        fgm39:e.fgm39,fga39:e.fga39,
        fgm40:e.fgm40,fgm50:e.fgm50,fgm60:e.fgm60,
        xpm:e.xpm,xpa:e.xpa,
        kr_tds:e.kr_tds,pr_tds:e.pr_tds

      }

      try{
        console.log("attempting to send data to server");
        //const response = await axios.post('add_player',data);
        const response =  await axiosPrivate.put('/player/addPlayerGameStats',data);
        console.log ("Response");
        console.log (response);
        successfulUpdate();
      }
      catch(err){
        console.log("error in add player stats: " + err);
      }
    }

  }


  const submitTeamStats = async (e) => {
    console.log ("Submitting player stats");
    console.log(e);


    //to get team   -- Document.getelementbyid - so need to assign id to <Input> boxes
    //console.log(e.team);
    //console.log("ret " + ret);
    //console.log("Access");
    //console.log(accessToken);

    if (league==='NFL'){
      var data = {
        game_id: game,
        win: 1
      }
      //send NFL post to add team to db for nflteams
      if (winner==='AwayWin'){
        data.team = away;
      }
      else if (winner==='HomeWin'){
        data.team = home;
      }
      //TODO: add support for tie (also may want to note loser in TeamGameStats)


      try{
        console.log("attempting to send data to server");
        //const response = await axios.post('add_player',data);
        const response =  await axiosPrivate.put('/game/addTeamGameStats',data);
        console.log ("Response");
        console.log (response);
        successfulUpdate();
      }
      catch(err){
        console.log("error in add team stats: " + err);
      }
    }

  }


  const createGame = async (e) => {
    console.log(e);
    const data = {
      away_team: e.awayteam,
      home_team: e.hometeam,
      time: e.time.format("YYYY-MM-DD HH:mm:ss"),
      type: e.type,
      week: e.week,
      timeframe: timeframe
    }
    console.log(data)
    await axiosPrivate.put('/game/createGame',data);
    successfulUpdate();
  }

  const handleTeamMenuClick = (e) => {
    //console.log("click ", e);
    console.log("Handle Team Menu Click");
    console.log("Choose Team");
    console.log(e);

  };

  const fillTeamMenu = (league) => {
    console.log("Filling the teams");

    console.log("teamItems old")
    console.log(teamItems)
    console.log("teamItems new")
    console.log(NFLteamItems)

    console.log("local team Map")

    console.log(NFLteamMap);
    NFLteamItems.sort(labelSorter);
    setTeamItems(NFLteamItems);
    setteamMap(NFLteamMap);

  };

  const handleTeamClick = (e) => {
    //console.log("click ", e);
    console.log("Handle Team Click");
    console.log(e);
    console.log("team key: " + e.key);
    console.log(e.key);
    console.log(teamMap)
    console.log("team corresponding to key: " + teamMap.get(parseInt(e.key)))
    setTeam(e.key);
    setTeamName(teamMap.get(parseInt(e.key)))
    setPlayerName("Select A Player");
    if (view==='enterplayerstats') {
      fillPlayerMenu(e.key);
    }
  };

  const fillPlayerMenu = (team) => {
    console.log("Filling the players");
    console.log(team)
    const data = {
      team: team,
      timeframe: timeframe
    }
    var myMap = new Map();
    axios.post('/getmarket/teamPlayers',data)
    .then((res) => {
      console.log("res is " + res);
    console.log("res.data is " + res.data); //debugger
    console.log(res.data);
    var playerItemsLoc = [];

    for (let i=0; i<res.data.length; ++i){
      const record = res.data[i];
      const playerName = record.fname + " " + record.lname;
      const pid = record.player_id;
      playerItemsLoc.push({
          label: playerName,
          key: pid
      })
      myMap.set(pid,playerName)
    }
    console.log("playerItems")
    console.log(playerItemsLoc)
    playerItemsLoc.sort(labelSorter);
    setplayerItems(playerItemsLoc)
    setplayerMap(myMap);

    console.log(playerItems);
  })
};

  const handlePlayerClick = (e) => {
    //console.log("click ", e);
    console.log("Chose Player");
    console.log(e);
    console.log(e.key);
    setPlayer(e.key);
    setPlayerName(playerMap.get(parseInt(e.key)))
  };



  const handlePlayerMenuClick = (e) => {
    //console.log("click ", e);

    console.log("Handle Player Menu Click");
    console.log("Choose a Player");
    console.log(e);

  };



  const TeamMenu = () => (
    <Menu onClick={handleTeamClick} items={teamItems}>
    </Menu>
  );



  const PlayerMenu = () => (
    <Menu onClick={handlePlayerClick} items = {playerItems}>
    </Menu>
  );


  const ChooseTeam = () => (
    <Dropdown trigger={['click']} overlay={TeamMenu}>
      <Button onClick={handleTeamMenuClick}>{teamName}<DownOutlined /></Button>

    </Dropdown>
  );



  const ChoosePlayer = () => (
    <Dropdown trigger={['click']} overlay={PlayerMenu}>
    <Button onClick={handlePlayerMenuClick}>{playerName}<DownOutlined /></Button>

  </Dropdown>
  );




  const handleClickAdmin = (e) => {
    //console.log("click ", e);
    console.log("handle click Admin");
    console.log(e);
    console.log(e.key);
    setIsSuccess(false);
    setView(e.key);

  };

  const getWeekGames = async (week) => {
    const data = {
      timeframe: timeframe,
      week: week
    }

    const response = await axiosPrivate.post('/game/getWeekGames',data);
    console.log(response);

    var gameOptions = [];
    var games = {};

    for (let i=0; i<response.data.length;++i){
      const home = response.data[i].home_team;
      const away = response.data[i].away_team;
      const game_id = response.data[i].game_id;
      const obj = [away,home];
      console.log(home)
      console.log(NFLteamMap.get(home));
      gameOptions.push({value:game_id,label:[NFLteamMap.get(away) + ' vs. ' + NFLteamMap.get(home)]})
      games[game_id] = obj;
    }
    console.log(games);
    setGameOptions(gameOptions)
    setGames(games);

  }

  const handleWeekChoice = (e) => {
    //console.log("click ", e);
    console.log("Handle Week Click");
    console.log(e);
    console.log("week: " + e.week);
    setWeek(e.week);
    getWeekGames(e.week);
  };

  const ChooseWeek = () => (


    <Form onFinish={handleWeekChoice}>
      <Form.Item label="Week" name="week" rules={[{ required: true, message: "Provide a Week." }]}>
        <InputNumber min={-3} />
      </Form.Item>
      <Form.Item>
          <Button type='primary' htmlType="submit">Submit Week Choice</Button>
        </Form.Item>
    </Form>
  );


  const handleGameChoice = (e) => {
    //console.log("click ", e);
    console.log("Handle Game Click");
    console.log(e);
    console.log("game: " + e.game);
    setWinner(-1);
    setGame(e.game);
    console.log(games[e.game]);
    const awayteam = games[e.game][0]
    const hometeam = games[e.game][1]
    const gameitems = [{key:awayteam,label:NFLteamMap.get(awayteam)},{key:hometeam,label:NFLteamMap.get(hometeam)}];
    setAway(awayteam);
    setHome(hometeam);
    setTeamItems(gameitems);
  };

  const ChooseGame = () => (
    <Form onFinish={handleGameChoice}>
      <Form.Item label="Game" name="game" rules={[{ required: true, message: "Provide a Week." }]}>
        <Select  placeholder="Select a Game" options={gameOptions}></Select>
      </Form.Item>
      <Form.Item>
          <Button type='primary' htmlType="submit">Submit Game Choice</Button>
      </Form.Item>
    </Form>


  );

  const componentMap = {
    "": null,
    creategame: (
      <div>

        <div>
      <Form onFinish={e=>createGame(e)}>
        <Form.Item label="Week" name="week" rules={[{ required: true, message: "Provide a Week." }]}>
          <InputNumber min={-3} />
        </Form.Item>
        <Form.Item label="Away Team" name="awayteam">
          <Select placeholder="Select a Team" style={{ width: 200 }} options={NFLteamOptions}>
          </Select>
        </Form.Item>
        <Form.Item label="Home Team" name="hometeam">
          <Select placeholder="Select a Team" style={{ width: 200 }} options={NFLteamOptions}>
          </Select>
        </Form.Item>
        {/*<Form.Item label="Day" name="day">
          <Select placeholder="Select a Day" style={{ width: 200 }}>
            <Option value="Thursday">Thursday</Option>
            <Option value="Friday">Friday</Option>
            <Option value="Saturday">Saturday</Option>
            <Option value="Sunday">Sunday</Option>
            <Option value="Monday">Monday</Option>
          </Select>
    </Form.Item> */}
        <Form.Item label="Time" name="time">
          <DatePicker showTime={{format: 'h:mm a'}} showNow={false}  />
        </Form.Item>
        <Form.Item label="Type" name="type">
          <Select placeholder="Select a Game Type" style={{ width: 200 }}>
            <Option value={0}>Pre-Season</Option>
            <Option value={1}>Regular Season</Option>
            <Option value={2}>Wild Card Playoffs</Option>
            <Option value={3}>Divisional Playoffs</Option>
            <Option value={4}>Conference Championship</Option>
            <Option value={5}>Super Bowl</Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType="submit">Create Game</Button>
        </Form.Item>
     </Form>
     </div>
    </div>
    ),
    enterplayerstats: (
      <div>
        {<ChooseWeek></ChooseWeek>}
        {week!==0 && <ChooseGame></ChooseGame>}
        {game!==0 && <ChooseTeam></ChooseTeam>}
        {team!==-1 && <ChoosePlayer></ChoosePlayer>}
        {playerName!=='Select A Player' && <div>
          <Form onFinish={submitPlayerStats} initialValues={{pa_yards:0,pa_tds:0,ints:0,ru_yards:0,
                                                              ru_tds:0,fumb:0,rec:0,re_yards:0,
                                                              re_tds:0,twoptconv:0,opi:0,dpi:0,fgm39:0,fga39:0,
                                                              fgm40:0,fgm50:0,fgm60:0,xpm:0,xpa:0,
                                                              kr_tds:0,pr_tds:0}}> 
            <Form.Item label='Passing Yards' name='pa_yards'>
              <InputNumber  precision={0}/>
            </Form.Item>
            <Form.Item label='Passing TDs' name='pa_tds'>
              <InputNumber precision={0} min={0}/>
            </Form.Item>
            <Form.Item label='Interceptions' name='ints'>
              <InputNumber precision={0} min={0}/>
            </Form.Item>
            <Form.Item label='Rushing Yards' name='ru_yards'>
              <InputNumber precision={0}/>
            </Form.Item>
            <Form.Item label='Rushing TDs' name='ru_tds'>
              <InputNumber precision={0} min={0}/>
            </Form.Item>
            <Form.Item label='Fumbles' name='fumb'>
              <InputNumber precision={0} min={0}/>
            </Form.Item>
            <Form.Item label='Receptions' name='rec'>
              <InputNumber precision={0} min={0}/>
            </Form.Item>
            <Form.Item label='Receiving Yards' name='re_yards'>
              <InputNumber precision={0}/>
            </Form.Item>
            <Form.Item label='Receiving TDs' name='re_tds'>
              <InputNumber precision={0} min={0}/>
            </Form.Item>
            <Form.Item label='Two Point Conversions' name='twoptconv'>
              <InputNumber precision={0} min={0}/>
            </Form.Item>
            <Form.Item label='OPI Yards' name='opi'>
              <InputNumber precision={0}/>
            </Form.Item>
            <Form.Item label='DPI Yards' name='dpi'>
              <InputNumber precision={0} min={0}/>
            </Form.Item>
            <Form.Item label='FGM39under' name='fgm39'>
              <InputNumber precision={0} min={0}/>
            </Form.Item>
            <Form.Item label='FGA39under' name='fga39'>
              <InputNumber precision={0} min={0}/>
            </Form.Item>
            <Form.Item label='FGM40' name='fgm40'>
              <InputNumber precision={0} min={0}/>
            </Form.Item>
            <Form.Item label='FGM50' name='fgm50'>
              <InputNumber precision={0} min={0}/>
            </Form.Item>
            <Form.Item label='FGM60' name='fgm60'>
              <InputNumber precision={0} min={0}/>
            </Form.Item>
            <Form.Item label='Extra Points Made' name='xpm'>
              <InputNumber precision={0} min={0}/>
            </Form.Item>
            <Form.Item label='Extra Point Att' name='xpa'>
              <InputNumber precision={0} min={0}/>
            </Form.Item>
            <Form.Item label='Kick Return TDs' name='kr_tds'>
              <InputNumber precision={0} min={0}/>
            </Form.Item>
            <Form.Item label='Punt Return TDs' name='pr_tds'>
              <InputNumber precision={0} min={0}/>
            </Form.Item>
            <Form.Item>
              <Button type='primary' htmlType="submit">Submit</Button>
            </Form.Item>
          </Form>
        </div>}
      </div>
    ),
    enterteamstats: (
      <div>
        {<ChooseWeek></ChooseWeek>}
        {week!==0 && <ChooseGame></ChooseGame>}
        {game!==0 && <div>
          <Form onFinish={submitTeamStats}>
            <Form.Item label="Winner">
              <Radio.Group value={winner} onChange={(winnerOnChange)}>
                <Radio value="AwayWin">Away Team</Radio>
                <Radio value="HomeWin">Home Team</Radio>
                <Radio value="Tie">Tie</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item>
              <Button type='primary' htmlType="submit">Submit</Button>
            </Form.Item>
          </Form>
        </div>}
      </div>
    )
  };

  return (
      <div>
        {isSuccess && <div>{prevView} Update Successful</div>}
        <Select defaultValue={1} style={{ width: 200 }} onChange={value=>{leagueUpdate(value)}}>
            <Option value={1}>NFL</Option>
          </Select>
          <Select defaultValue={2} style={{ width: 200 }} onChange={value=>{seasonUpdate(value)}}>
            <Option value={1}>Regular Season</Option>
            <Option value={2}>Playoffs</Option>
          </Select>
        <Menu onClick={handleClickAdmin} mode='horizontal'>

            <Menu.Item key="creategame"><Button>Create Game</Button></Menu.Item>
            <Menu.Item key="enterplayerstats"><Button>Enter Player Stats for Game</Button></Menu.Item>
            <Menu.Item key="enterteamstats"><Button>Enter Team Stats for Game</Button></Menu.Item>
        </Menu>



        {isSuccess && <Button onClick={repeatAction}>Repeat Action</Button>}
        {view!=="All" && <div>{view}</div>}
        {componentMap[view]}
      </div>
  );
  
}

export default AdminGame;
