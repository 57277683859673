import React from "react";
import './App.css';
import { List } from "antd";


const SiteNews = (props) => {

    const data = ["Cade York craters after being cut", "Puka, Love both drop over 20% after injuries", "Isiah Likely leads Week 1 with 27% gain", "Congrats to @sruppert12 on $10 win for highest Week 1 gain!", "Welcome to the 2024 NFL Season!"];


  return (
    <div>
    {/*<div class="module-header">
        <header class="module-list content">
            <h2>Site News</h2>
        </header>
        <ul class="module-list content">{data[0]}</ul>
    </div> */}
    <div>
    <List size="small" header={<div>SiteNews</div>} bordered dataSource={data} renderItem={(item) => <List.Item>{item}</List.Item>}>

    </List>
    </div></div>
  );
}

export default SiteNews;
